import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';

import {
  useAuth,
  useUser
} from "@clerk/clerk-react";

const environment = process.env.REACT_APP_ENVIRONMENT; //prod or dev

let MAIN_API_NAME = "voice_replicator_ai"

let MAIN_API_URL = "https://funquiz.app/modular2/voice-replicator-1"

function UserEmailAddress() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.primaryEmailAddress.emailAddress;
}

function FxInputForm3() {
    const [selectedFile, setSelectedFile] = useState(null);
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };
  
    //const authenticatedFetch = useFetch();
    const { getToken } = useAuth();
    const emailAddr = UserEmailAddress();

    const [textOutput, setTextOutput] = useState('');

    const inputProps = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };
  
    const handleTextOutputChange = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextOutput(event.target.value);
    };


    const [textInput1, setTextInput1] = useState('');
    const handleTextInputChange1 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput1(event.target.value);
    };

    const [textInput2, setTextInput2] = useState('');
    const handleTextInputChange2 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput2(event.target.value);
    };
  
    const inputProps1 = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };
  
    function submitInput() {
    }
    
    function handleUpload() {
      setTextOutput("Loading...");
      // Implement upload logic here, e.g., using Fetch API or a library like Axios
      if (selectedFile) {
        const formData = new FormData();
        formData.append('sound1', selectedFile);
        formData.append('text', textInput1);
        formData.append('language', textInput2);
        formData.append('email', emailAddr);
        formData.append('env', environment);
        formData.append('api_name',MAIN_API_NAME)
        // console.log(selectedFile);
  
        const fetchX = async () => {
          const myToken = await getToken();
          // Make an API request to upload the file
          fetch(MAIN_API_URL, {
            mode: 'cors',
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${myToken}`,
            },
            body: formData,
          })
          .then(response => response.json())
          .then(response => {
            // Handle response from server
            console.log(response["message"]);
            setTextOutput(response["message"]);
          })
          .catch(error => {
            // Handle error
            setTextOutput("Sorry, action failed");//error.message);
            console.error(error);
            //console.log("error")
          });
        };
        fetchX();
      }
    }
  
    return (
      <div>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <TextField 
          id="outlined-basic" 
          label="Text to synthesise..." 
          variant="outlined" 
          InputProps={inputProps}
          value={textInput1}
          multiline
          onChange={handleTextInputChange1}/>
        </Box>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <TextField 
          id="outlined-basic" 
          label="Language... (e.g. en)" 
          variant="outlined" 
          InputProps={inputProps}
          value={textInput2}
          multiline
          onChange={handleTextInputChange2}/>
        </Box>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <p>Choose an audio snippet of the voice you want to replicate:</p>
        </Box>
        <Box display="flex" 
             marginTop={2} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <input type="file" accept="audio/*" onChange={handleFileChange} />
        {/*<button onClick={handleUpload}>Upload</button>*/}
        </Box>
        <Box display="flex" 
             marginTop={5} 
             marginBottom={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <Button variant="contained"
                onClick={handleUpload}>Submit Input</Button>
        </ Box>
        <Box marginTop={0} marginBottom={5}>
        <div
          style={{
            whiteSpace: "pre-line", // Preserve line breaks and spaces
            height: "300px",       // Set a fixed height
            overflow: "auto",      // Enable scrolling if content overflows
            border: "1px solid #ccc",
            padding: "10px",
          }}
        > {textOutput} </div>
        </Box>
      </div>
    );
  }

  export default FxInputForm3;